
import TrustLogo from '../assets/images/trust_support.png'
import ChatLogo from '../assets/images/chat-logo.png'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChatContext, WalletsContext } from '../context'
import { TChatData, TWalletData } from '../types'
import "../assets/css/chat_css.css"
import axios from 'axios'
import { SERVER_URL, HEADERS } from '../constants'


export const WalletChatPage = () => {

    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const navigate = useNavigate()
    const [wallet, setWallet] = useState<TWalletData>()
    const { id } = useParams()
    const [messageInput, setMessageInput] = useState<string>()
    const { chatContext, setChatContext } = useContext(ChatContext)
    const [currentChat, setCurrentChat] = useState<TChatData>() 
    const [a, setA] = useState<boolean>(false)

    useEffect(() => {
        walletsContext?.forEach((wl) => {
            if (wl.id == Number(id)) {
                setWallet(wl)
                setChatContext(wl.chat)
                setCurrentChat(wl.chat)
                if (a == false) {
                    setA(true)
                    setInterval(() => {
                        axios.post(SERVER_URL + 'chat/get', { id: wl.chat.id }, { headers: HEADERS })
                            .then(response => {
                                setChatContext(response.data)
                            })
                            .catch(error => {
                            })
                    }, 1000)
                }
            }
        })
    }, [])

    useEffect(() => {
        walletsContext?.forEach((wl) => {
            if (wl.id == Number(id)) {
                setWallet(wl)
                setChatContext(wl.chat)
                if (a == false) {
                    setA(true)
                    setInterval(() => {
                        axios.post(SERVER_URL + 'chat/get', { id: wl.chat.id }, { headers: HEADERS })
                            .then(response => {
                                setChatContext(response.data)
                            })
                            .catch(error => {
                            })
                    }, 1000)
                }
            }
        })
    }, [walletsContext])

    const sendMessage = () => {
        if (messageInput != '') {
            const messageData = {
                text: messageInput,
                chatik: wallet?.chat.id,
                admin: true
            }
            console.log(messageData)
            axios.post(SERVER_URL + 'message/save', messageData, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setMessageInput('')
                    axios.post(SERVER_URL + 'chat/get', { id: chatContext?.id }, { headers: HEADERS })
                        .then(response => {
                            setChatContext(response.data)
                            console.log(response.data)
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }


    return (
        <div className="layout">
            <div className="layout__header">
                <header className="topbar container">
                    <div className="topbar__inner container-inner">
                        <div className="topbar__col">
                            <a title="Client" onClick={() => navigate('/wallet/' + String(wallet?.id))}>
                                <div className="logo-wrapper">
                                    <div className="logo">
                                        <img src={TrustLogo} alt="logo" />
                                    </div>
                                </div>
                                <p className="help-center-name">
                                    Trust Wallet
                                </p>
                            </a>
                        </div>
                    </div>
                </header>
            </div >
            <div className="layout__content">
                <div className="container">
                    <div className="container-inner clearfix">
                        <div className="new-ticket-page">
                            <div className="row">
                                <div className="column">
                                    <h1 className="new-ticket-title">
                                        Client Chat
                                    </h1>
                                    <div className="new-ticket-form">
                                        <div className="chat-container">
                                            <div className="chat-widget">
                                                <div className="chat-main-container">
                                                    <div className="chat-container-header">
                                                        <div className="chat-header-logo-container">
                                                            <img alt="logo" src={ChatLogo} />
                                                        </div>
                                                    </div>
                                                    <div className="chat-content-container">
                                                        <div className="chat-content tw-scrollbar">
                                                            {chatContext?.messages?.map((ms) => {
                                                                if (ms.admin == false) {
                                                                    return <ManagerMessage message={ms} />
                                                                }
                                                                else {
                                                                    return <UserMessage message={ms} />
                                                                }
                                                            })}
                                                        </div>
                                                        <div className="chat-box-container">
                                                            <input className="chat-box-input" type="text" name="chat-box" placeholder="Tell us something..." value={messageInput} onChange={(event) => {setMessageInput(event.target.value)}} />
                                                            <div className="sumbit-btn" onClick={sendMessage}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                                                    <path d="M1.4 17.7307L18.85 10.2507C19.0304 10.1739 19.1842 10.0457 19.2923 9.88211C19.4004 9.71854 19.4581 9.52679 19.4581 9.33072C19.4581 9.13464 19.4004 8.94289 19.2923 8.77932C19.1842 8.61574 19.0304 8.48756 18.85 8.41071L1.4 0.930714C1.2489 0.864808 1.08377 0.837557 0.919509 0.851417C0.755246 0.865277 0.597018 0.919813 0.459098 1.01011C0.321179 1.1004 0.207908 1.22361 0.129505 1.36861C0.0511009 1.51362 0.010031 1.67587 0.00999999 1.84071L0 6.45071C0 6.95071 0.37 7.38071 0.87 7.44071L15 9.33072L0.87 11.2107C0.37 11.2807 0 11.7107 0 12.2107L0.00999999 16.8207C0.00999999 17.5307 0.74 18.0207 1.4 17.7307Z" fill="#FCFCFE" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


const ManagerMessage = ({ message }: { message: any }) => {
    return (
        <div className="manager-message">
            <div className="message-container">
                <div className="message-content">
                    <p className="message-text">
                        {message?.text}
                    </p>
                    <p className="message-time">{message?.dateCreated}</p>
                </div>
                <div className="manager-message-tail">
                    <svg width="17" height="21" viewBox="0 0 17 21" fill="rgb(72, 255, 145)" xmlns="http://www.w3.org/2000/svg"><path d="M0.569448 20.3567C5.76945 21.1567 10.9028 18.2933 12.5694 16.46C10.8516 12.3635 21.4574 2.41398 14.4574 2.4136C12.8388 2.4136 11.4563 -1.82648 5.56945 1.35672C5.54824 2.64356 5.56945 7.09793 5.56945 7.85632C5.56945 18.3563 -0.430552 19.7534 0.569448 20.3567Z" fill="rgb(72, 255, 145)"></path></svg>
                </div>
            </div>
            <div className="css-y5mt1y">
            </div>
        </div>
    )
}

const UserMessage = ({ message }: { message: any }) => {
    return (
        <div className="user-message">
            <div className="message-container">
                <div className="message-content">
                    <p className="message-text">
                        {message.text}
                    </p>
                    <p className="message-time">{message.dateCreated}</p>
                </div>
                <div className="manager-message-tail">
                    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5822 20.5755C11.3822 21.3755 6.24883 18.5121 4.58216 16.6788C6.30003 12.5823 -4.30575 2.63273 2.69425 2.63235C4.31286 2.63235 5.69531 -1.60773 11.5822 1.57547C11.6034 2.86231 11.5822 7.31668 11.5822 8.07507C11.5822 18.5751 17.5822 19.9722 16.5822 20.5755Z" fill="#F25226"></path></svg>
                </div>
            </div>
            <div className="css-y5mt1y">
            </div>
        </div>
    )
}