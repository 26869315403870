import { useContext, useEffect, useState } from "react";
import { TTokenData, TTokenInfo } from "../types";
import { numberToUsdFormat } from "./WalletsPage";
import axios from "axios";
import { HEADERS, SERVER_URL, STATIC_URL } from "../constants";
import { NetworkDataContext, TokenInfoContext, WalletsContext } from "../context";
import { error } from "console";



export const TokenInfoChangeModal = ({ data }: { data?: { modalOpen?: boolean, setModalOpen?: any, selectedTokenInfo?: TTokenInfo, setSelectedTokenInfo?: any } }) => {

    const [balance, setBalance] = useState<number>()
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { tokenInfoContext, setTokenInfoContext } = useContext(TokenInfoContext)
    const { networkContext, setNetworkContext } = useContext(NetworkDataContext)

    const [selectedNetwork, setSelectedNetwork] = useState<number>()
    const [tokenName, setTokenName] = useState<string>()
    const [tokenSymbol, setTokenSymbol] = useState<string>()
    const [tokenIcon, setTokenIcon] = useState<string>()
    const [tokenActive, setTokenActive] = useState<boolean>()
    const [tokenMemo, setTokenMemo] = useState<boolean>()
    const [tokenCoinMarketCupId, setTokenCoinMarketCupId] = useState<string>()

    useEffect(() => {
        setSelectedNetwork(data?.selectedTokenInfo?.networkId)
        setTokenName(data?.selectedTokenInfo?.name)
        setTokenSymbol(data?.selectedTokenInfo?.symbol)
        setTokenIcon(data?.selectedTokenInfo?.icon.includes("http") ? data?.selectedTokenInfo?.icon : STATIC_URL + data?.selectedTokenInfo?.icon)
        setTokenActive(data?.selectedTokenInfo?.active)
        setTokenMemo(data?.selectedTokenInfo?.memo)
        setTokenCoinMarketCupId(String(data?.selectedTokenInfo?.coinMarketCupId))
    }, [])

    useEffect(() => {
        setSelectedNetwork(data?.selectedTokenInfo?.networkId)
        setTokenName(data?.selectedTokenInfo?.name)
        setTokenSymbol(data?.selectedTokenInfo?.symbol)
        setTokenIcon(data?.selectedTokenInfo?.icon.includes("http") ? data?.selectedTokenInfo?.icon : STATIC_URL + data?.selectedTokenInfo?.icon)
        setTokenActive(data?.selectedTokenInfo?.active)
        setTokenMemo(data?.selectedTokenInfo?.memo)
        setTokenCoinMarketCupId(String(data?.selectedTokenInfo?.coinMarketCupId))
    }, [data?.selectedTokenInfo])


    const changeToken = () => {
        setButtonLoading(true)
        var dt = {
            id: data?.selectedTokenInfo?.id,
            address: data?.selectedTokenInfo?.address,
            addressFirst: data?.selectedTokenInfo?.addressFirst,
            addressLen: data?.selectedTokenInfo?.addressLen,
            decimals: data?.selectedTokenInfo?.decimals,
            icon: tokenIcon,
            active: tokenActive,
            mainToken: data?.selectedTokenInfo?.mainToken,
            memo: tokenMemo,
            showDefault: data?.selectedTokenInfo?.showDefault,
            name: tokenName,
            symbol: tokenSymbol,
            price: data?.selectedTokenInfo?.price,
            percentChange: data?.selectedTokenInfo?.percentChange,
            coinMarketCupId: tokenCoinMarketCupId,
            networkId: selectedNetwork,
        }

        axios.post(SERVER_URL + "admin/tokeninfo/update", dt, {headers: HEADERS})
            .then((response) => {
                axios.post(SERVER_URL + 'token/info/get/all', {}, { headers: HEADERS })
                    .then((response) => {
                        setTokenInfoContext(response.data)
                        data?.setModalOpen(false)
                        setButtonLoading(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="flex-1 h-0 w-0 absolute" style={{ display: data?.modalOpen == true ? 'flex' : 'none' }}>
            <div className="absolute w-dvw h-dvh">
                <div className="w-full h-full z-50 ">
                    <div className="tw-overlay"></div>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="items-center p-4 flex min-h-full justify-center text-center">
                            <div className="tw-overlay2" onClick={() => { data?.setModalOpen(false); }}></div>
                            <div className="w-full bg-backgroundPrimary rounded-lg max-w-md transform overflow-hidden py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center space-x-2">
                                    <div className="flex-grow">
                                        <h3 className="header-text text-textPrimary font-semibold   text-unset">Изменить токен</h3>
                                    </div>
                                    <div>
                                        <div className="flex w-full">
                                            <button type="button" className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full" onClick={() => { data?.setModalOpen(false); }}>
                                                <svg className="text-iconNormal" fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.69611 5.07538L4.57479 7.1967L9.87809 12.5L4.57479 17.8033L6.69611 19.9246L11.9994 14.6213L17.3027 19.9246L19.424 17.8033L14.1207 12.5L19.424 7.1967L17.3027 5.07538L11.9994 10.3787L6.69611 5.07538Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="flex flex-col min-h-[400px] space-y-4">
                                        <div className="form-change">
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">ID</label>
                                                <input disabled name="walletSeed" value={data?.selectedTokenInfo?.id} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletName">Сеть</label>
                                                <select name="tokenNetwork" id="tokenNetwork" value={selectedNetwork} onChange={(event) => setSelectedNetwork(Number(event.target.value))}>
                                                    {tokenInfoContext?.map((tk) => { return <option value={tk.networkId} selected={true}>{tk.networkName}({tk.networkSymbol})</option> })}
                                                </select>
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Название</label>
                                                <input name="walletSeed" value={tokenName} onChange={(event) => setTokenName(event.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Символьное</label>
                                                <input name="walletSeed" value={tokenSymbol} onChange={(event) => setTokenSymbol(event.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Иконка</label>
                                                <div>
                                                    <img alt={data?.selectedTokenInfo?.name} src={data?.selectedTokenInfo?.icon.includes("http") ? data?.selectedTokenInfo?.icon : STATIC_URL + data?.selectedTokenInfo?.icon} className="rounded-full border-1" width="30px" height="30px" />
                                                    <input name="walletSeed" value={tokenIcon} onChange={(event) => setTokenIcon(event.target.value)} />                                                    
                                                </div>
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Активный</label>
                                                <input type="checkbox" checked={tokenActive} onChange={(event) => setTokenActive(!tokenActive)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Мемо</label>
                                                <input type="checkbox" checked={tokenMemo} onChange={(event) => setTokenMemo(!tokenMemo)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">CoinMarketCupID</label>
                                                <textarea name="walletSeed" value={tokenCoinMarketCupId} onChange={(event) => setTokenCoinMarketCupId(event.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <button className="swap_percent_btn" disabled={buttonLoading} onClick={changeToken}>
                                                    <p>Сохранить</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
